import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import MobileSearch from "components/MobileSearchForm/MobileSearch";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
  className={`nc-SectionHero3 relative ${className}`}
  data-nc-id="SectionHero3"
>
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="hidden lg:block w-full max-w-full">
      <HeroSearchForm />
    </div>
  </div>
  <div className=" lg:hidden px-4 my-4">
      <MobileSearch />
    </div>
  <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 z-20">
    <img
      className="absolute inset-0 object-cover"
      src={imagePng}
      alt="hero"
    />
  </div>
</div>

  );
};

export default SectionHero3;
